// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { withRouter, RouteComponentProps } from 'react-router-dom';
// import { getAllLocationsOrders, getMultipleMeetingsExcelSummary } from '../../Actions/orderActions';
// import { DateRangePicker, Range } from 'react-date-range';
// import dayjs from 'dayjs';
// import '../Settings/restaurant.css'
// import { defaultStaticRanges } from '../../Common/date-picker/rangesList';
// // import i18n from '../../i18n';
// import { WithTranslation } from 'react-i18next';
// import CustomPopUp from '../../Common/Popups/CustomPopUp';
// import isEmpty from '../../Utilities/isEmpty';
// import { ArrayOfLength } from '../../Utilities/ArraysUtilities';
// import Skeleton from 'react-loading-skeleton';
// import { get_multiple_meetings_excel_summary_loading_prefix } from '../../Reducers/restaurantReducer';
// import { getPrecedingZero } from '../../Utilities/getPrecedingZero';
// import ReactTooltip from 'react-tooltip';
// import { getZonedTime } from '../../Utilities/getZonalTime';
// import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import { GetRestaurantLocations, RestaurantLocation } from '../../Interfaces/PayloadsAndResponses/GetRestaurants';
// import { DashboardRef, DateRange, Params } from '../Dashboard/Dashboard';
// import { GetAllLocationsOrders } from '../../Interfaces/PayloadsAndResponses/Orders';
// import SingleDayMeetings from './SingleDayMeetings';
// import DayMeeting from './DayMeeting';

// export const OrderStatus = {
//   'Rejected by vendor': '#DC3545',
//   'Confirmed': '#30a64a',
//   'Unconfirmed': '#646464'
// }

// type AllLocationsOrdersProps = {
//   i18n: WithTranslation | null;
//   dateRange: DateRange[];
//   dashboardRef: DashboardRef;
//   // mapStateToProps
//   meetings: GetAllLocationsOrders | null;
//   excel_summary_loading: {
//     [key: string]: boolean | undefined;
//   };
//   meetings_loading: boolean;
//   restaurant_addresses: GetRestaurantLocations | null;
//   // mapDispatchToProps
//   getAllLocationsOrders: (id: string | number, startDate: string, endDate: string, address_ids?: number[]) => void;
//   getMultipleMeetingsExcelSummary: (restaurant_address_ids: (string | number)[], date: string, DownloadingPDF: () => void) => void;
// } & RouteComponentProps<Params>;

// type RestaurantFilters = (RestaurantLocation & { isChecked?: boolean })
// type SelectedMeetings = string
// type List = {id:any,isChecked:boolean}
// type AllLocationsOrdersState = {
//   isOpenOrderCalendar: boolean,
//   isOpenRestaurents: boolean,
//   restaurantsList: RestaurantFilters[],
//   toggle: boolean;
//   selectedMeetings: SelectedMeetings[];
//   allMeetings: GetAllLocationsOrders | null;
//   selectedConut: {[key:string] : number};
//   finalArray: any;
//   checkedMeeting: boolean;
//   newList: List[];
  
// }

// let meetings: any = []
// class AllLocationsOrders extends Component<AllLocationsOrdersProps, AllLocationsOrdersState> {

//   constructor(props: AllLocationsOrdersProps) {
//     super(props);
//     this.state = {
//       isOpenOrderCalendar: false,
//       isOpenRestaurents: false,
//       restaurantsList: [],
//       toggle: false,
//       selectedMeetings: [],
//       allMeetings: this.props.meetings,
//       selectedConut: {
//         '' : 0,
//       },
//       finalArray: [],
//       checkedMeeting: false,
//       newList : [{id:'1',isChecked:false},{id:'2',isChecked:false},{id:'3',isChecked:false},{id:'4',isChecked:false}]
//     }
//   }

//   getSelectedRestaurants = (restaurantsList: RestaurantFilters[]) => {
//     const selected_restaurants_ids: number[] = []
//     restaurantsList.forEach(restaurant => { restaurant.isChecked && selected_restaurants_ids.push(restaurant.id) });
//     return selected_restaurants_ids;
//   }

//   getSelectedRestaurantsOrderData = (selected_restaurants_ids: number[]) => {
//     const { match: { params: { restaurantId } }, dateRange } = this.props;
//     const { startDate, endDate } = dateRange[0] || {};
//     if (!isEmpty(selected_restaurants_ids) && restaurantId) {
//       this.props.getAllLocationsOrders(restaurantId, dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD'), selected_restaurants_ids)
//     }
//   }

//   setRestaurantListData = () => {
//     const { restaurant_addresses } = this.props;

//     if (restaurant_addresses && !isEmpty(restaurant_addresses?.addresses)) {
//       const selected_restaurants_ids: number[] = [], restaurantsList: RestaurantFilters[] = [];
//       restaurant_addresses?.addresses.forEach(restaurant => {
//         selected_restaurants_ids.push(restaurant.id);
//         restaurantsList.push({ ...restaurant, isChecked: true });
//       })
//       this.setState({ restaurantsList: restaurantsList });
//       this.getSelectedRestaurantsOrderData(selected_restaurants_ids);
//     }
//   }

//   componentDidMount() {
//     this.setRestaurantListData();
//   }

//   componentDidUpdate(prevProps: AllLocationsOrdersProps) {
//     const { restaurant_addresses } = this.props;
//     if (restaurant_addresses !== prevProps.restaurant_addresses && !isEmpty(restaurant_addresses?.addresses)) {
//       this.setRestaurantListData()
//     }
//   }

//   navigateToOrderDetails = (restaurantId: number, meetingId: number) => {
//     const { history } = this.props;
//     history.push(`/dashboard/restaurant/${restaurantId}/meetings/${meetingId}`);
//   }

//   handleSelect = (item: { selection: Range }) => {
//     const { history, match: { params: { restaurantId } } } = this.props;
//     //@ts-ignore
//     this.props.dashboardRef.setState({ dateRange: [item.selection] });
//     const { startDate, endDate } = item.selection || {};
//     const selectedRestaurants = this.getSelectedRestaurants(this.state.restaurantsList) ?? [];
//     if (!isEmpty(selectedRestaurants) && restaurantId) {
//       history?.push(`/dashboard/restaurant/${restaurantId}/meetings?from=${dayjs(startDate)?.format('YYYY-MM-DD')}&to=${dayjs(endDate)?.format('YYYY-MM-DD')}`);
//       this.props.getAllLocationsOrders(restaurantId, dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD'), selectedRestaurants);
//     }
//   };

//   handleChange = (e: React.ChangeEvent<HTMLInputElement>, index?: number) => {
//     const { name, checked } = e.target;
//     const { restaurantsList } = this.state;
//     let updatedRestaurantsList = [...restaurantsList];
//     if (name === 'select-all') {
//       updatedRestaurantsList = updatedRestaurantsList.map(restaurant => ({ ...restaurant, isChecked: checked }));
//     } else if (index !== undefined && updatedRestaurantsList[index]) {
//       updatedRestaurantsList[index].isChecked = checked;
//     }
//     this.setState({ restaurantsList: updatedRestaurantsList });
//     this.getSelectedRestaurantsOrderData(this.getSelectedRestaurants(updatedRestaurantsList));
//   };
//   handleChangeNew = (e: React.ChangeEvent<HTMLInputElement>, index?: number) => {
//     const { name, checked } = e.target;
//     const { newList } = this.state;
//     let updatedRestaurantsList = [...newList];
//     if (name === 'select-all') {
//       updatedRestaurantsList = newList.map(value => ({ ...value, isChecked: checked }));
//     } else if (index !== undefined && updatedRestaurantsList[index]) {
//       updatedRestaurantsList[index].isChecked = checked;
//     }
//     this.setState({ newList: updatedRestaurantsList });
//     // this.getSelectedRestaurantsOrderData(this.getSelectedRestaurants(updatedRestaurantsList));
//   };

//   handleChangeForOrders = (e: React.ChangeEvent<HTMLInputElement>, date: any,resname:any, index:number, resList:any) => {
//     const { checked } = e.target;
//     const { selectedMeetings } = this.state;
//     let tempArray:any = []
//     const result = resList[resname]
//     result.forEach((singleRes:any)=>{
//           tempArray.push(singleRes.order_id)
//         })
//         this.setState({finalArray: {[resname]:tempArray}})
//     if (checked){
//       this.setState({selectedConut: { resname : this.state.selectedConut?.resname+1}})
//     }else{
//       this.setState({selectedConut: { resname : this.state.selectedConut?.resname-1}})
//     }
//     let updatedSelectedMeetings = [...selectedMeetings];

//     // if (name === 'select-all-orders') {
//     //   updatedSelectedMeetings = updatedSelectedMeetings.map(restaurant => ({ ...restaurant, isChecked: checked }));
//     // } else if (index !== undefined && updatedSelectedMeetings[index]) {
//     //   updatedSelectedMeetings[index].isChecked = checked;
//     // }
//     this.setState({ selectedMeetings: updatedSelectedMeetings });
//     // this.getSelectedRestaurantsOrderData(this.getSelectedRestaurants(updatedSelectedMeetings));
//   };

//   getOrderLast24HoursTrend = (order_count: number) => {
//     if (order_count > 0) return { className: 'up', count: order_count, color: '#218655' }
//     else if (order_count < 0) return { className: 'down', count: Math.abs(order_count), color: '#dc3545' }
//     else return null;
//   }

//   getExcelSummaryForAllMeetingsOnSingleDay = (restaurant_ids: number[], date: string, DownloadingPDF: () => void) => {
//     this.props.getMultipleMeetingsExcelSummary(restaurant_ids, date, DownloadingPDF);
//   }

//   toggler = () => {
//     this.setState({ toggle: !this.state.toggle, selectedMeetings: meetings })
//   }
//   render() {
//     // const { i18n } = this.props;
//     const { isOpenOrderCalendar, isOpenRestaurents, restaurantsList = [] } = this.state;
//     const { dateRange, meetings, meetings_loading, excel_summary_loading } = this.props;


//     const { startDate, endDate } = dateRange[0] || {};
//     const popUpBodyStyles = { maxHeight: 'calc(100vh - 200px)', overflow: 'auto', top: 45, boxShadow: '0px 2px 13px #00000019' }
//     let isAllRestaurantsSelected = true;
//     let isAllRestaurantsSelectedNew = true;
//     const selectedRestaurantsCount = restaurantsList.filter(restaurant => {
//       isAllRestaurantsSelected = isAllRestaurantsSelected ? Boolean(restaurant.isChecked) : isAllRestaurantsSelected;
//       return restaurant.isChecked === true
//     }).length;

//     const allDeliveriesInRange = (selectedRestaurantsCount < 1 ? {} : meetings?.deliveries) || {};
//     const daysInRange = Object.keys(allDeliveriesInRange);
//     const daySelectAllOrderArray = Object.values(allDeliveriesInRange)
//     // const dayCheckAll = daySelectAllOrderArray.map((val,ind)=>{
//     //    const temp = Object.values(val)
//     //  return  temp.map((v,i)=>{
//     //     let temp2= {...v[i], checked : true}
//     //     return temp2
//     //    })
//     // })

//     const checkerselect= (e: React.ChangeEvent<HTMLInputElement>, date: any, id:number,idx:number,resname:string) => {
//       const { name } = e.target;
//       // const oldMeetings = this.props.meetings
//       // const targetobj = oldMeetings?.deliveries ? oldMeetings.deliveries : {}
//       // let updatedSelectedMeetings = [...meetingsArray];
//       // this.setState({selectedCount : this.state.selectedConut+1})
//       // oldMeetings?.deliveries
//       // let temp=Object.keys(targetobj)
//       //  const res = temp.filter(val => val === date)
//       //  const found =targetobj[res[0]]

//        daySelectAllOrderArray.filter((item)=> item)
//       // updatedSelectedMeetings = updatedSelectedMeetings.map(restaurant => );
//       if (name === 'select-all-orders') {
//       }
//       //  else if (index !== undefined && updatedSelectedMeetings[index]) {
//       //   updatedSelectedMeetings[index].isChecked = checked;
//       // }
//       // this.setState({ selectedMeetings: updatedSelectedMeetings });
//       // this.getSelectedRestaurantsOrderData(this.getSelectedRestaurants(updatedSelectedMeetings));
//     };

//     const vendor = JSON.parse(localStorage.getItem('user') || '{}');
//     const vendorTimeZone = vendor && vendor.time_zone;


//     const locationDropdown = restaurantsList?.length > 1 ? (
//       <div className='dropdown me-2 flex-grow'>
//         {restaurantsList && restaurantsList.map((restaurant, index: number) => (
//           <div className='d-flex align-items-start' key={index}>
//             <input
//               type='checkbox'
//               name={`${restaurant.id}`}
//               id={`${restaurant.id}`}
//               checked={Boolean(restaurant?.isChecked)}
//               onChange={e => this.handleChange(e, index)}
//               className='cursor-pointer mt-1'
//             />
//             <label htmlFor={`${restaurant.id}`} className='pl-3 cursor-pointer'>
//               <span className='order-reataurant-names d-inline-block mb-1'>{restaurant?.name?.split(':')?.[0]}</span>
//               <br />
//               <span className='order-reataurant-names ' style={{ whiteSpace: 'pre-wrap' }}>{restaurant?.address_line}</span>
//             </label>
//           </div>
//         ))}
//       </div>
//     ) : '';

//     const SingleDayOrdersHeading = ({ singleDay, restaurant_ids_for_single_day_orders, dateForDownloadingSummary, isDownloadingExcelSummary }: {
//       singleDay: string[] | string, restaurant_ids_for_single_day_orders: number[], dateForDownloadingSummary: string, isDownloadingExcelSummary: (boolean | undefined);
//     }) =>
//       <div className='d-flex flex-wrap align-items-center' style={{ 'width': '100%' }}>
//         <div className='mr-3 order-date-heading'>
//           <h3 className='m-0 order-date'>{dayjs(`${singleDay}`).format('ddd MMM DD')}</h3>
//         </div>
//         {this.state.checkedMeeting ? <div className='order-accept-container'>
//           <button className='btn accept-order-btn '>
//             Accept Order
//           </button>
//         </div>
//         :
//         <div className='order-accept-container'>
//           <button className='btn accept-order-btn ' disabled>
//             Accept Order
//           </button>
//         </div> }
//         {/* <div className='order-download-container'>
//           <button
//             // style={{ minWidth: 157 }}
//             onClick={() => this.getExcelSummaryForAllMeetingsOnSingleDay(
//               [...new Set(restaurant_ids_for_single_day_orders as unknown as number[])],
//               dateForDownloadingSummary
//             )}
//             disabled={isDownloadingExcelSummary}
//             className={`btn  order-download-btn ${(this.state.finalArray?.length) > 0 ? 'order-download-btn-selected' : ''}`}
//           >
//             {isDownloadingExcelSummary ?
//               'Downloading...' : 'Download \t'+ `${(this.state.finalArray?.length) > 0 ? `(${this.state.finalArray.length })`:''}`
//             }
//           </button>
//           <button className='btn oder-download-arrow-btn' onClick={this.toggler}>
//             <i style={{ fontSize: 14 }} className='far fa-arrow-to-bottom mx-2'></i>
//           </button>
//           <div className={`${this.state.toggle ? 'download-options-show' : 'download-options-hide'}`}>
//             <div className='download-options-list'>
//               <p className='p-2'>Summary(PDF)</p>
//               <p className='p-2'>Orders(EXCEL)</p>
//               <p className='p-2'>Print Labels</p>
//             </div>
//           </div>
//         </div> */}
//         <div className='order-download-container'>
//         <button
//           // style={{ minWidth: 157 }}
//           // onClick={() => this.getExcelSummaryForAllMeetingsOnSingleDay(
//           //   [...new Set(restaurant_ids_for_single_day_orders as unknown as number[])],
//           //   dateForDownloadingSummary
//           // )}
//           disabled={isDownloadingExcelSummary}
//           className={`btn  order-download-btn `}
//         >
//           {isDownloadingExcelSummary ?
//             'Downloading...' : 'Download '
//           }
//           {/* <i style={{ fontSize: 14 }} className='fas fa-arrow-down mr-2'></i> */}
//         </button>
//         <button className='btn oder-download-arrow-btn' onClick={this.toggler}>
//           {/* <i class="far fa-arrow-to-bottom"></i> */}
//           <i style={{ fontSize: 14 }} className='far fa-arrow-to-bottom mx-2'></i>
//         </button>


//         <div>
//           {this.state.toggle &&
//             <CustomPopUp
//               backdropStyles={{ opacity: 0, cursor: 'default' }}
//               onPopUpClose={() => this.setState({toggle: false})}
//               popUpClassName='order-history-range-picker'
//               popUpBodyStyles={{ left: 0 }}
//             >
//               <div
//                 style={{ cursor: 'default', width: 172, maxHeight: 160, overflowY: 'auto' }}
//                 className='bg-white order-restaurant-selector'
//               >
//                 {/* <div className='d-flex align-items-center'>
//                             <input
//                               type='checkbox'
//                               name='select-all'
//                               id='select-all-toggler'
//                               checked={isAllRestaurantsSelected}
//                               onChange={this.handleChange}
//                               className='cursor-pointer'
//                             />
//                             <label htmlFor='select-all-toggler' className='m-0 cursor-pointer pl-3 order-reataurant-names'>
//                               {isAllRestaurantsSelected ? 'UnSelect All' : 'Select All'}
//                             </label>
//                           </div>
//                           <hr />
//                           {locationDropdown} */}
//             <div className='download-options-list'>
//               <p className='p-2'>Summary(PDF)</p>
//               <p className='p-2'>Orders(EXCEL)</p>
//               <p className='p-2'>Print Labels</p>
//             </div>
//                 {/* <div className='download-options-list' >
//                   <p className='p-2' onClick={() => getBulkMeetingPDFDownloader(singleDay)}>Summary(PDF)</p>
//                   <p className='p-2'onClick={() => getExcelSummaryForAllMeetingsOnSingleDay(
//             [...new Set(restaurant_ids_for_single_day_orders as unknown as number[])],
//             dateForDownloadingSummary
//           )}>Orders(EXCEL)</p>
//                   <p className='p-2' onClick={() => getBulkMeetingLabelPDFDownloader(singleDay)}>Print Labels</p>
//                 </div> */}
//               </div>
//             </CustomPopUp>}
//         </div>


//         {/* <div className={`${toggler ? 'download-options-show' : 'download-options-hide'}`}>
//           <div className='download-options-list'>
//             <p className='p-2'>Summary(PDF)</p>
//             <p className='p-2'>Orders(EXCEL)</p>
//             <p className='p-2'>Print Labels</p>
//           </div>
//         </div> */}
//       </div>
//       </div>

//     return (
//       isEmpty(meetings) ?
//         <MeetingsLoading /> :
//         <>
//           <div className='order-header'>
//             <div className='order-header-container'>
//               <div className='d-flex justify-content-center align-items-center'>
//                 <h3 className='text-white order-heading'><span>Upcoming Orders</span></h3>
//               </div>
//               <div className='order-filters'>
//                 {restaurantsList?.length > 1 &&
//                   <div className='btn-orders order-container-button ml-md-auto position-relative'
//                     onClick={() => this.setState({ isOpenRestaurents: true })}
//                   >
//                     <i className='fas fa-solid fa-store' />
//                     <span
//                       className='mx-2 d-inline-block'
//                       style={{ fontSize: 15, fontWeight: 600 }}>
//                       {isAllRestaurantsSelected ? 'All' : getPrecedingZero(selectedRestaurantsCount)} Restaurants
//                     </span>
//                     <i
//                       className={`fas fa-solid fa-chevron-up animated-chevron-${isOpenRestaurents ? 'down' : 'up'}`}
//                       style={{ fontSize: 15 }}
//                     />
//                     {isOpenRestaurents &&
//                       <CustomPopUp
//                         backdropStyles={{ opacity: 0, cursor: 'default' }}
//                         onPopUpClose={() => this.setState({ isOpenRestaurents: false })}
//                         popUpClassName='order-history-range-picker'
//                         popUpBodyStyles={{ ...popUpBodyStyles, left: 0 }}
//                       >
//                         <div
//                           style={{ cursor: 'default', width: 425, maxHeight: 281, overflowY: 'auto' }}
//                           className='text-dark text-left p-3 oveflow-auto bg-white invisible-scroll order-restaurant-selector'
//                         >
//                           <div className='d-flex align-items-center'>
//                             <input
//                               type='checkbox'
//                               name='select-all'
//                               id='select-all-toggler'
//                               checked={isAllRestaurantsSelected}
//                               onChange={this.handleChange}
//                               className='cursor-pointer'
//                             />
//                             <label htmlFor='select-all-toggler' className='m-0 cursor-pointer pl-3 order-reataurant-names'>
//                               {isAllRestaurantsSelected ? 'UnSelect All' : 'Select All'}
//                             </label>
//                           </div>
//                           <hr />
//                           {locationDropdown}
//                         </div>
//                       </CustomPopUp>}
//                   </div>
//                 }
//                 <div className='p-2 rounded cursor-pointer position-relative order-container-calendar'
//                   onClick={() => this.setState({ isOpenOrderCalendar: true })}
//                 // style={{ boxShadow: '0px 2px 3px #0000000D' }}
//                 >
//                   <div className='d-flex align-items-center'>
//                     <i className={`fas fa-solid fa-calendar`} />
//                     <span className='ml-3 mr-4' style={{ fontSize: 15, fontWeight: 600 }}>{dayjs(startDate).format('MM.DD.YYYY')} - {dayjs(endDate).format('MM.DD.YYYY')}</span>
//                     <i
//                       className={`fas fa-solid fa-chevron-up animated-chevron-${isOpenOrderCalendar ? 'down' : 'up'}`}
//                       style={{ fontSize: 15 }}
//                     />
//                   </div>
//                   {isOpenOrderCalendar &&
//                     <CustomPopUp
//                       backdropStyles={{ opacity: 0, cursor: 'default' }}
//                       onPopUpClose={() => this.setState({ isOpenOrderCalendar: false })}
//                       popUpClassName='order-history-range-picker'
//                       popUpBodyStyles={{ ...popUpBodyStyles, right: 0 }}
//                     >
//                       <DateRangePicker
//                         onChange={range => this.handleSelect(range as { selection: Range })}
//                         // showSelectionPreview={true}
//                         showDateDisplay={false}
//                         moveRangeOnFirstSelection={false}
//                         months={1}
//                         ranges={this.props.dateRange}
//                         staticRanges={defaultStaticRanges}
//                         className='bg-white w-100 overflow-auto'
//                       />
//                     </CustomPopUp>}
//                 </div>
//               </div>
//             </div>
//             <div className='order-cards-div'>
//               {/* <h3 className='mb-4' style={{ paddingTop: '1.25rem' }}>Upcoming Totals</h3> */}
//               <div className='text-center upcoming-order-container  order-card-container'>
//                 {meetings!.total_orders.map(({ pickup_at, total_orders, total_items }, index: number) =>
//                   <div key={pickup_at} className='upcoming-order-card bg-white cursor-default'>
//                     <div className='d-flex justify-content-center align-items-center ml-2 mr-4 '>
//                       <div className=' mx-3 my-0'>
//                         <p className='text-muted pl-1 upcoming-order-cards-title'>Orders</p>
//                         <p style={{ fontSize: 28 }}>{getPrecedingZero(total_orders)}</p>
//                       </ div>
//                       <span className='pt-3 pr-1'>|</span>
//                       <div className='pl-2 m-0'>
//                         <p className='text-muted pl-1 upcoming-order-cards-title'>Items</p>
//                         <p style={{ fontSize: 28 }}>{getPrecedingZero(total_items)}</p>
//                       </div>
//                     </div>
//                     <p className={` text-muted ${index === 0 ? 'today-orders-total' : ''}`} style={{ fontWeight: 600 }}>
//                       {index === 0 ? 'Today' : dayjs(pickup_at).format('dddd MM-DD-YY')}
//                     </p>
//                   </div>
//                 )}
//               </div>

//             </div>
//           </div>

//           {/* <h3 className='mb-4' style={{ paddingTop: '1.25rem' }}>Upcoming Totals</h3>
//           <div className='row justify-content-between text-center upcoming-order-container mx-0 order-cards' style={{ background: 'rgb(244, 248, 251)' }}>
//             {meetings!.total_orders.map(({ pickup_at, total_orders, total_items }, index: number) =>
//               <div key={pickup_at} className='upcoming-order-card bg-white cursor-default'>
//                 <div className='d-flex justify-content-center align-items-center'>
//                   <p className='pr-2'>
//                     <span style={{ fontSize: 20 }}>{getPrecedingZero(total_orders)}</span>
//                     <span className='text-muted pl-1'>Orders</span>
//                   </ p>
//                   <span className='mb-2'>|</span>
//                   <p className='ps-2'>
//                     <span style={{ fontSize: 20 }}>{getPrecedingZero(total_items)}</span>
//                     <span className='text-muted ps-1'>Items</span>
//                   </p>
//                 </div>
//                 <p className={`${index === 0 ? 'today-orders-total' : ''}`} style={{ fontWeight: 600 }}>
//                   {index === 0 ? 'Today' : dayjs(pickup_at).format('ddd MMM DD')}
//                 </p>
//               </div>
//             )}
//           </div> */}
//           <div className=' order-order-history ' style={{ gap: 15 }}>
//             <div>
//               {/* <h3 className='mb-1 pb-1 order-history-heading'>{i18n && i18n.t('Order History')}</h3> */}
//             </div>

//             {/* <div className='order-accept-parent'>
//               {daysInRange && daysInRange[0] ?
//                 <SingleDayOrdersHeading
//                   singleDay={daysInRange[0]}
//                   isDownloadingExcelSummary={excel_summary_loading[get_multiple_meetings_excel_summary_loading_prefix + dayjs(daysInRange[0]).format('YYYY-MM-DD')]}
//                   dateForDownloadingSummary={dayjs(daysInRange[0]).format('YYYY-MM-DD')}
//                   restaurant_ids_for_single_day_orders={Object.keys(allDeliveriesInRange[daysInRange[0]]).map((name: string) => allDeliveriesInRange[daysInRange[0]][name][0].restaurant_address_id)}
//                 /> :
//                 <h3 className='my-3'>{i18n && i18n.t('Order History')}</h3>
//               }
//               <div className='order-accept-container'>
//                 <button className='btn accept-order-btn '>
//                   Accept Order
//                 </button>
//               </div>
//             </div> */}
//             {/* <div className='order-accept-btn'>
//               <button className='btn accept-order-btn '>
//                 Accept Order
//               </button>
//             </div> */}
//             {/* <div className='d-flex flex-wrap order-1 order-lg-2' style={{ gap: 20, fontWeight: 600 }}>
//               {restaurantsList?.length > 1 &&
//                 <div className='text-white btn btn-orders ms-md-auto position-relative'
//                   onClick={() => this.setState({ isOpenRestaurents: true })}
//                   style={{ backgroundColor: '#157ffc', minWidth: 168 }}
//                 >
//                   <i className='fas fa-solid fa-store' />
//                   <span
//                     className='mx-2 d-inline-block text-white'
//                     style={{ fontSize: 16, fontWeight: 600 }}>
//                     {isAllRestaurantsSelected ? 'All' : getPrecedingZero(selectedRestaurantsCount)} Restaurants
//                   </span>
//                   <i
//                     className={`fas fa-solid fa-chevron-up animated-chevron-${isOpenRestaurents ? 'down' : 'up'}`}
//                     style={{ fontSize: 15 }}
//                   />
//                   {isOpenRestaurents &&
//                     <CustomPopUp
//                       backdropStyles={{ opacity: 0, cursor: 'default' }}
//                       onPopUpClose={() => this.setState({ isOpenRestaurents: false })}
//                       popUpClassName='order-history-range-picker'
//                       popUpBodyStyles={{ ...popUpBodyStyles, left: 0 }}
//                     >
//                       <div
//                         style={{ cursor: 'default', width: 450, maxHeight: 425, overflowY: 'auto' }}
//                         className='text-dark text-left p-3 oveflow-auto bg-white invisible-scroll'
//                       >
//                         <div className='d-flex align-items-center'>
//                           <input
//                             type='checkbox'
//                             name='select-all'
//                             id='select-all-toggler'
//                             checked={isAllRestaurantsSelected}
//                             onChange={this.handleChange}
//                             className='cursor-pointer'
//                           />
//                           <label htmlFor='select-all-toggler' className='m-0 cursor-pointer ps-3'>
//                             {isAllRestaurantsSelected ? 'UnSelect All' : 'Select All'}
//                           </label>
//                         </div>
//                         <hr />
//                         {locationDropdown}
//                       </div>
//                     </CustomPopUp>}
//                 </div>
//               }
//               <div className='p-2 rounded cursor-pointer position-relative'
//                 onClick={() => this.setState({ isOpenOrderCalendar: true })}
//                 style={{ backgroundColor: '#147BFC', boxShadow: '0px 2px 3px #0000000D' }}>
//                 <div className='d-flex align-items-center text-white'>
//                   <i className={`fas fa-solid fa-calendar`} />
//                   <span className='ms-3 me-4'>{dayjs(startDate).format('MM-DD-YYYY')} - {dayjs(endDate).format('MM-DD-YYYY')}</span>
//                   <i
//                     className={`fas fa-solid fa-chevron-up animated-chevron-${isOpenOrderCalendar ? 'down' : 'up'}`}
//                     style={{ fontSize: 15 }}
//                   />
//                 </div>
//                 {isOpenOrderCalendar &&
//                   <CustomPopUp
//                     backdropStyles={{ opacity: 0, cursor: 'default' }}
//                     onPopUpClose={() => this.setState({ isOpenOrderCalendar: false })}
//                     popUpClassName='order-history-range-picker'
//                     popUpBodyStyles={{ ...popUpBodyStyles, right: 0 }}
//                   >
//                     <DateRangePicker
//                       onChange={range => this.handleSelect(range as { selection: Range })}
//                       // showSelectionPreview={true}
//                       showDateDisplay={false}
//                       moveRangeOnFirstSelection={false}
//                       months={1}
//                       ranges={this.props.dateRange}
//                       staticRanges={defaultStaticRanges}
//                       className='bg-white w-100 overflow-auto'
//                     />
//                   </CustomPopUp>}
//               </div>
//             </div> */}
//           </div>
//           {meetings_loading ?
//             <MeetingsLoading isFilterLoading={meetings_loading} /> :
//             isEmpty(daysInRange) ?
//               <div>
//                 <h2 className='mt-5 text-center'>No Order Found.</h2>
//               </div> :
//               daysInRange?.map((singleDay, index: number) => {
//                 const singleDayOrdersRestaurants = allDeliveriesInRange[singleDay];
//                 const dateForDownloadingSummary = dayjs(singleDay).format('YYYY-MM-DD');
//                 const restaurant_ids_for_single_day_orders: number[] = [];
//                 const isDownloadingExcelSummary = excel_summary_loading[get_multiple_meetings_excel_summary_loading_prefix + dateForDownloadingSummary];
//                 return <React.Fragment key={singleDay}> {true ? <div key={singleDay}>
//                   <DayMeeting
//                   singleDay={singleDay}
//                   singleDayOrdersRestaurants={singleDayOrdersRestaurants}
//                   dateForDownloadingSummary={dateForDownloadingSummary}
//                   restaurant_ids_for_single_day_orders={restaurant_ids_for_single_day_orders}
//                   isDownloadingExcelSummary={isDownloadingExcelSummary}
//                   // restaurantOrdersOnSingleDay={restaurantOrdersOnSingleDay}
//                   getExcelSummaryForAllMeetingsOnSingleDay={this.getExcelSummaryForAllMeetingsOnSingleDay}
//                   index={index}
//                   getOrderLast24HoursTrend={this.getOrderLast24HoursTrend}
//                   navigateToOrderDetails={this.navigateToOrderDetails}
//                   vendorTimeZone = {vendorTimeZone}
//                 />
//                 </div>
//                  :
//                 <React.Fragment key={singleDay}>
//                   <SingleDayOrdersHeading
//                     singleDay={singleDay}
//                     isDownloadingExcelSummary={isDownloadingExcelSummary}
//                     dateForDownloadingSummary={dateForDownloadingSummary}
//                     restaurant_ids_for_single_day_orders={restaurant_ids_for_single_day_orders}
//                   />
//                   {/* {index === 0 ? null :
//                   } */}
//                   <div className='table-responsive mb-3 bg-primary'>
//                     {Object.keys(singleDayOrdersRestaurants).map((restaurantName: string) => {
//                       const restaurantOrdersOnSingleDay = singleDayOrdersRestaurants[restaurantName];
//                       return <>
//                       { false ?
//                       <div style={{ marginBottom: 10 }} key={restaurantName}>
//                         <div className='d-flex align-items-center pb-2 pt-4'>
//                           <div className='d-flex align-items-center'>
//                             <input
//                               type='checkbox'
//                               name='select-all'
//                               id='select-all-toggler'
//                               checked={isAllRestaurantsSelectedNew}
//                               onChange={this.handleChangeNew}
//                               className='cursor-pointer'
//                             />
//                             <label htmlFor='select-all-toggler' className='m-0 cursor-pointer pl-3 order-reataurant-names'>
//                               {isAllRestaurantsSelectedNew ? 'UnSelect All' : 'Select All'}
//                             </label>
//                           </div>
//                           <div>
//                           </div>
//                           <label htmlFor="select-all-orders" className='mb-0'>
//                             <i className='fas fa-store' style={{ color: '#157FFC' }} />
//                             <h5 className='pl-2 m-0 d-inline-block' style={{ color: '#157FFC' }}>{restaurantName}</h5>
//                           </label>
//                         </div>
//                         <table className='table order-history-records table-hover border-0 table-data text-center'>
//                           <tbody>
//                             {restaurantOrdersOnSingleDay.map(({
//                               pickup,
//                               status,
//                               order_id,
//                               cutoff_at,
//                               items_count,
//                               restaurant_address_id,
//                               new_items_in_last24_hours,
//                               checked
//                             },idx,array) => {
//                               restaurant_ids_for_single_day_orders.push(restaurant_address_id);
//                               const last24HoursOrderTrend = this.getOrderLast24HoursTrend(new_items_in_last24_hours || 0);
//                               const isCutoffReached = dayjs(cutoff_at).isBefore(new Date());
//                               return <tr className='bg-white tableList border-bottom cursor-default' key={order_id} >
//                                 <td className='order-checkbox'>
//                                   <input type="checkbox" name="single-order" id="single-order" checked={checked} onChange={(e)=> checkerselect(e,singleDay,order_id,idx,restaurantName)} />
//                                 </td>
//                                 <td className='border-0 text-nowrap text-left table-data-padding'>
//                                   <span style={{ fontSize: 18 }}>{order_id}</span>
//                                   <p style={{ fontSize: 16 }}>Order ID</p>
//                                 </td>
//                                 <td className='border-0 text-nowrap text-left'>
//                                   <span style={{ fontSize: 18 }}>{dayjs(pickup).format('hh:mm a')}</span>
//                                   <p style={{ fontSize: 16 }}>Pickup</p>
//                                 </td>
//                                 <td className='border-0 text-nowrap text-left'>
//                                   <span style={{ fontSize: 18 }}>{getPrecedingZero(items_count)}</span>
//                                   <p style={{ fontSize: 16 }}>Items
//                                     {last24HoursOrderTrend ?
//                                       <span style={{ color: last24HoursOrderTrend.color, paddingLeft: 1 }}>
//                                         (<i className={`fa-solid fa-arrow-trend-${last24HoursOrderTrend.className} mx-1`}></i>
//                                         <span className='me-1' style={{ fontSize: 16 }}>{last24HoursOrderTrend.count}</span>)
//                                       </span> : null}
//                                   </p>
//                                 </td>
//                                 <td className='border-0 text-nowrap text-left'>
//                                   <span
//                                     data-tip
//                                     className='text-capitalize'
//                                     data-for={`order-history-${order_id}`}
//                                     style={{ fontSize: 18, color: isCutoffReached ? '#dc3545' : '#157ffc' }}
//                                   >
//                                     {isCutoffReached ? 'Reached' : dayjs(cutoff_at).toNow(true)}
//                                   </span>
//                                   {window.navigator.userAgent.match(/MSIE|Trident/) === null &&  // check if not IE
//                                     <ReactTooltip
//                                       type='dark'
//                                       place='bottom'
//                                       effect='solid'
//                                       className='react-tooltip'
//                                       id={`order-history-${order_id}`}
//                                     >
//                                       <span>{getZonedTime(cutoff_at, 'EEE. MMM dd h:mma, zzzz', vendorTimeZone)}</span>
//                                     </ReactTooltip>
//                                   }
//                                   <p style={{ fontSize: 16 }}>Cutoff</p>
//                                 </td>
//                                 <td className='border-0 text-nowrap text-left' style={{ width: 165 }}>
//                                   <span
//                                     className='confirmed text-capitalize '
//                                     //@ts-ignore
//                                     style={{ backgroundColor: OrderStatus[status] }}
//                                   >
//                                     {status}
//                                   </span>
//                                 </td>
//                                 <td className='border-0 text-nowrap text-right pr-4' style={{ width: 150 }}>
//                                   <button
//                                     className='btn btn-sm btn-light'
//                                     onClick={() => this.navigateToOrderDetails(restaurant_address_id, order_id)}
//                                   >
//                                     View Details
//                                   </button>
//                                 </td>
//                               </tr>
//                             }

//                             )}
//                           </tbody>
//                             </table>
//                           </div>
//                           :
//                           <div key={restaurantName} className='bg-primary'>

//                             <SingleDayMeetings
//                               singleDay={singleDay}
//                               index={restaurantName}
//                               restaurant_ids_for_single_day_orders={restaurant_ids_for_single_day_orders}
//                               getOrderLast24HoursTrend={this.getOrderLast24HoursTrend}
//                               navigateToOrderDetails={this.navigateToOrderDetails}
//                               vendorTimeZone={vendorTimeZone}
//                               restaurantOrdersOnSingleDay={restaurantOrdersOnSingleDay}
//                               restaurantName={restaurantName}

//                             />
//                           </div>
//                         }</>
//                     }
//                     )}
//                     </div>
//                   </React.Fragment>}
//                 </React.Fragment>
//               })
//           }
//         </>
//     )
//   }
// }

// const mapStateToProps = (state: ReduxRootState) => ({
//   meetings: state.restaurant_data.meetings,
//   excel_summary_loading: state.restaurant_data.get_multiple_meetings_excel_summary_loading,
//   meetings_loading: state.restaurant_data?.meetings_loading,
//   restaurant_addresses: state.restaurant_data.restaurant_addresses,
//   i18n: state.i18n.i18n

// })

// export default connect(mapStateToProps, { getAllLocationsOrders, getMultipleMeetingsExcelSummary })(withRouter(AllLocationsOrders));


// const MeetingsLoading = ({ isFilterLoading = false }: { isFilterLoading?: boolean }) => {
//   return <>
//     {!isFilterLoading ?
//       <div className='order-header'>
//         <div className='order-header-container'>
//           <div className='d-flex justify-content-center align-items-center'>
//             <h3 className='text-white order-heading'>
//               <Skeleton width={239} height={32} />
//             </h3>
//           </div>
//           <div className='order-filters'>
//             <Skeleton width={239} height={32} />
//             <div className='rounded cursor-pointer position-relative'
//             >
//               <Skeleton width={239} height={32} />
//             </div>
//           </div>
//         </div>
//         <div className='order-cards-div'>
//           <div className='text-center upcoming-order-container  order-card-container'>
//             {ArrayOfLength(5).map((item) =>
//               <div className='upcoming-order-card border-0 p-0' key={item}>
//                 <Skeleton width={200} height={95} />
//               </div>
//             )}
//           </div>
//         </div>
//         <div className=''>
//         <Skeleton width={200} height={40} />
//         </div>
//       </div> :
//       null
//     }
//     {ArrayOfLength(2).map(index => {
//       const singleDayOrders = ArrayOfLength(3 - index);
//       return <React.Fragment key={index}>
//         <div className='date d-flex flex-wrap align-items-center mt-4 mb-2 pt-4 '>
//           <h4 className='m-0'>
//             <Skeleton width={84} height={25} />
//           </h4>
//           <button className='ml-3 btn btn-light overflow-hidden p-0'>
//             <Skeleton height={35} width={158} />
//           </button>
//           <button className=' btn btn-light overflow-hidden p-0'  style={{ marginLeft: 'auto' }}>
//             <Skeleton height={35} width={158} />
//           </button>
//         </div>
//         <div className='table-responsive mb-3'>
//           <table className='table order-history-records table-hover border-0 table-data text-center'>
//             <tbody>
//               {singleDayOrders.map((rowIndex) =>
//                 <tr className='border-bottom bg-white' key={rowIndex + index}>
//                   <td className='border-0 p-2'>
//                     <Skeleton width='100%' height={65} />
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </React.Fragment>
//     })}
//   </>
// }

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getAllLocationsOrders, getMultipleMeetingsExcelSummary } from '../../Actions/orderActions';
import { DateRangePicker } from 'react-date-range';
import ActionCable from 'actioncable';
import dayjs from 'dayjs';
import '../Settings/restaurant.css'
import { defaultStaticRanges } from '../../Common/date-picker/rangesList';
import CustomPopUp from '../../Common/Popups/CustomPopUp';
import isEmpty from '../../Utilities/isEmpty';
import { ArrayOfLength } from '../../Utilities/ArraysUtilities';
import Skeleton from 'react-loading-skeleton';
import { get_multiple_meetings_excel_summary_loading_prefix } from '../../Reducers/restaurantReducer';
import { getPrecedingZero } from '../../Utilities/getPrecedingZero';
import ReactTooltip from 'react-tooltip';
import { getZonedTime } from '../../Utilities/getZonalTime';
import { RestaurantLocation } from '../../Interfaces/PayloadsAndResponses/GetRestaurants';
import { DateRange } from '../Dashboard/Dashboard';
import SingleDayMeetings from './SingleDayMeetings';
import DayMeeting from './DayMeeting';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';

export const OrderStatus = {
  'Rejected by vendor': '#DC3545',
  'Confirmed': '#30a64a',
  'Unconfirmed': '#646464',
  'Cancelled': '#DC3545'
}

type AllLocationsOrdersProps = {
  dateRange: DateRange[];
  handleDashBoardState?: ((item: DateRange[]) => void) | undefined;
  // mapDispatchToProps
  getAllLocationsOrders?: (id: string | number, startDate: string, endDate: string, address_ids?: number[]) => void;
  getMultipleMeetingsExcelSummary?: (restaurant_address_ids: (string | number)[], date: string, DownloadingPDF: () => void) => void;
}
type DateRangeSocket = {
  startDate: Date,
  endDate: Date,
  key?: number,
}

type RestaurantFilters = (RestaurantLocation & { isChecked?: boolean })
type SelectedMeetings = string
type List = {id:any,isChecked:boolean}

const AllLocationsOrders = ({dateRange, handleDashBoardState}: AllLocationsOrdersProps) => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const { restaurantId } = useParams<{ restaurantId: string }>();

  const meetings = useSelector((state: ReduxRootState) => state?.restaurant_data?.meetings);
  const excel_summary_loading = useSelector((state: ReduxRootState) => state?.restaurant_data?.get_multiple_meetings_excel_summary_loading);
  const meetings_loading = useSelector((state: ReduxRootState) => state?.restaurant_data?.meetings_loading);
  const restaurant_addresses = useSelector((state: ReduxRootState) => state?.restaurant_data?.restaurant_addresses);

  const [isOpenOrderCalendar, setIsOpenOrderCalendar] = useState<boolean>(false);
  const [isOpenRestaurents, setIsOpenRestaurents] = useState<boolean>(false);
  const [restaurantsList, setRestaurantsList] = useState<RestaurantFilters[]>([]);
  const [toggle, setToggle] = useState<boolean>(false);
  const [selectedMeetings, setSelectedMeetings] = useState<SelectedMeetings[]>([]);
  const [rangeForSocket, setRangeForSocket] = useState<DateRangeSocket>()
  // const [selectedCount, setSelectedCount] = useState<{[key:string] : number}>({ '' : 0 });
  // const [finalArray, setFinalArray] = useState([]);
  const checkedMeeting: boolean = false;
  const [newRestaurantList, setNewRestaurantList] = useState<List[]>([{ id: '1', isChecked: false }, { id: '2', isChecked: false }, { id: '3', isChecked: false }, { id: '4', isChecked: false }]);
  const prevProps = useRef(restaurant_addresses);


  useEffect(() => {
    setRestaurantListData();
    //eslint-disable-next-line
  }, []);


  const successfullConnection = (data: any) => {
    const { startDate, endDate } = rangeForSocket || {};
    const selectedRestaurants = getSelectedRestaurants(restaurantsList) ?? [restaurantId];
    if (!isEmpty(selectedRestaurants) && restaurantId) {
      dispatch(getAllLocationsOrders(restaurantId, dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD'), selectedRestaurants));
    }
  }

  useEffect(() => {

    var cable = ActionCable.createConsumer(`wss://admin-dev.chowmill.com/cable?header=${localStorage.getItem('headers')}`);

    const subscription = cable.subscriptions.create(
      { channel: 'VendorsChannel' },
      {
        connected() {
        },
        disconnected() {
        },
        received(data) {
          successfullConnection(data);
        },
      }
    );
    return () => {
      subscription.unsubscribe();
    };
    //eslint-disable-next-line
  }, [])


  useEffect(() => {
    if (restaurant_addresses !== prevProps.current && !isEmpty(restaurant_addresses?.addresses)) {
      setRestaurantListData();
    }
    //eslint-disable-next-line
  }, [restaurant_addresses]);

  const getSelectedRestaurants = (restaurantsList: RestaurantFilters[]) => {
    const selected_restaurants_ids: number[] = [];
    restaurantsList.forEach(restaurant => {
      if (restaurant.isChecked) {
        selected_restaurants_ids?.push(restaurant?.id);
      }
    });
    return selected_restaurants_ids;
  }

  const getSelectedRestaurantsOrderData = (selected_restaurants_ids: number[]) => {
    const { startDate, endDate } = dateRange[0] || {};
    setRangeForSocket({ startDate, endDate })
    if (!isEmpty(selected_restaurants_ids) && restaurantId) {
      dispatch(getAllLocationsOrders(restaurantId, dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD'), selected_restaurants_ids));
    }
  };

  const setRestaurantListData = () => {
    if (restaurant_addresses && !isEmpty(restaurant_addresses?.addresses)) {
      const selected_restaurants_ids: number[] = [];
      const restaurantsList: RestaurantFilters[] = [];

      restaurant_addresses?.addresses.forEach(restaurant => {
        selected_restaurants_ids.push(restaurant.id);
        restaurantsList.push({ ...restaurant, isChecked: true });
      });

      setRestaurantsList(restaurantsList);
      getSelectedRestaurantsOrderData(selected_restaurants_ids);
    }
  }


  const navigateToOrderDetails = (restaurantId: number, meetingId: number) => {
    history.push(`/dashboard/restaurant/${restaurantId}/meetings/${meetingId}`);
  }

  const handleSelect = (item: { selection: DateRange }) => {
    handleDashBoardState?.([item?.selection]);
    const { startDate, endDate } = item?.selection || {};
    setRangeForSocket({ startDate, endDate })
    const selectedRestaurants = getSelectedRestaurants(restaurantsList) ?? [];
    if (!isEmpty(selectedRestaurants) && restaurantId) {
      history?.push(`/dashboard/restaurant/${restaurantId}/meetings?from=${dayjs(startDate)?.format('YYYY-MM-DD')}&to=${dayjs(endDate)?.format('YYYY-MM-DD')}`);
      dispatch(getAllLocationsOrders(restaurantId, dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD'), selectedRestaurants));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index?: number) => {
    const { name, checked } = e.target;
    let updatedRestaurantsList = [...restaurantsList];
    if (name === 'select-all') {
      updatedRestaurantsList = updatedRestaurantsList?.map(restaurant => ({ ...restaurant, isChecked: checked }));
    } else if (index !== undefined && updatedRestaurantsList[index]) {
      updatedRestaurantsList[index].isChecked = checked;
    }
    setRestaurantsList(updatedRestaurantsList);
    dispatch(getSelectedRestaurantsOrderData(getSelectedRestaurants(updatedRestaurantsList)));
  };

  const handleChangeRestaurants = (e: React.ChangeEvent<HTMLInputElement>, index?: number) => {
    const { name, checked } = e.target;
    let updatedRestaurantsList = [...newRestaurantList];
    if (name === 'select-all') {
      updatedRestaurantsList = newRestaurantList?.map(value => ({ ...value, isChecked: checked }));
    } else if (index !== undefined && updatedRestaurantsList[index]) {
      updatedRestaurantsList[index].isChecked = checked;
    }
    setNewRestaurantList(updatedRestaurantsList);
  };

  // const handleChangeForOrders = (e: React.ChangeEvent<HTMLInputElement>, date: any, resname: any, index: number, resList: any) => {
  //   const { checked } = e.target;
  //   let tempArray: any = [];
  //   const result = resList[resname];

  //   result.forEach((singleRes: any) => {
  //     tempArray.push(singleRes.order_id);
  //   });

  //   setFinalArray((prevFinalArray: any) => ({
  //     ...prevFinalArray,
  //     [resname]: tempArray,
  //   }));

  //   if (checked) {
  //     setSelectedCount((prevCount) => ({
  //       ...prevCount,
  //       [resname]: (prevCount?.[resname] ?? 0) + 1,
  //     }));
  //   } else {
  //     setSelectedCount((prevCount) => ({
  //       ...prevCount,
  //       [resname]: (prevCount?.[resname] ?? 0) - 1,
  //     }));
  //   }
  //   let updatedSelectedMeetings = [...selectedMeetings];
  //   setSelectedMeetings(updatedSelectedMeetings);
  // };

  const getOrderLast24HoursTrend = (order_count: number) => {
    if (order_count > 0) return { className: 'up', count: order_count, color: '#218655' }
    else if (order_count < 0) return { className: 'down', count: Math.abs(order_count), color: '#dc3545' }
    else return null;
  };

  const getExcelSummaryForAllMeetingsOnSingleDay = (restaurant_ids: number[], date: string, DownloadingPDF: () => void) => {
    dispatch(getMultipleMeetingsExcelSummary(restaurant_ids, date, DownloadingPDF));
  }
  const toggler = () => {
    setToggle(!toggle);
    setSelectedMeetings(selectedMeetings);
  }

  const { startDate, endDate } = dateRange[0] || {};
  const popUpBodyStyles = {
    maxHeight: 'calc(100vh - 200px)',
    overflow: 'auto',
    top: 45,
    boxShadow: '0px 2px 13px #00000019'
  };

    let isAllRestaurantsSelected = true;
    let isAllRestaurantsSelectedNew = true;
    const selectedRestaurantsCount = restaurantsList?.filter((restaurant) => {
      isAllRestaurantsSelected = isAllRestaurantsSelected ? Boolean(restaurant.isChecked) : isAllRestaurantsSelected;
      return restaurant.isChecked === true;
    }).length;

    const allDeliveriesInRange = (selectedRestaurantsCount < 1 ? {} : meetings?.deliveries) || {};
    const daysInRange = Object.keys(allDeliveriesInRange);
    const daySelectAllOrderArray = Object.values(allDeliveriesInRange);

    const checkerselect = (e: React.ChangeEvent<HTMLInputElement>, date: any, id:number,idx:number,resname:string) => {
      const { name } = e.target;
      daySelectAllOrderArray?.filter((item) => item);
      if (name === 'select-all-orders') {
      }
      //  else if (index !== undefined && updatedSelectedMeetings[index]) {
      //   updatedSelectedMeetings[index].isChecked = checked;
      // }
      // this.setState({ selectedMeetings: updatedSelectedMeetings });
      // this.getSelectedRestaurantsOrderData(this.getSelectedRestaurants(updatedSelectedMeetings));
    };

    const vendor = JSON.parse(localStorage.getItem('user') || '{}');
    const vendorTimeZone = vendor && vendor.time_zone;


    const locationDropdown = restaurantsList?.length > 1 ? (
      <div className='dropdown me-2 flex-grow'>
        {restaurantsList && restaurantsList?.map((restaurant, index) => (
          <div className='d-flex align-items-start' key={index}>
            <input
              type='checkbox'
              name={`${restaurant.id}`}
              id={`${restaurant.id}`}
              checked={Boolean(restaurant?.isChecked)}
              onChange={(e) => handleChange(e, index)}
              className='cursor-pointer mt-1'
            />
            <label htmlFor={`${restaurant.id}`} className='pl-3 cursor-pointer'>
              <span className='order-reataurant-names d-inline-block mb-1'>{restaurant?.name?.split(':')?.[0]}</span>
              <br />
              <span className='order-reataurant-names ' style={{ whiteSpace: 'pre-wrap' }}>{restaurant?.address_line}</span>
            </label>
          </div>
        ))}
      </div>
    ) : '';

    const SingleDayOrdersHeading = ({
      singleDay,
      restaurant_ids_for_single_day_orders,
      dateForDownloadingSummary,
      isDownloadingExcelSummary,
    } : {singleDay: string[] | string, restaurant_ids_for_single_day_orders: number[], dateForDownloadingSummary: string, isDownloadingExcelSummary: (boolean | undefined)}) => (
      <div className='d-flex flex-wrap align-items-center' style={{ width: '100%' }}>
        <div className='mr-3 order-date-heading'>
          <h3 className='m-0 order-date'>{dayjs(`${singleDay}`).format('ddd MMM DD')}</h3>
        </div>
        {checkedMeeting ? <div className='order-accept-container'>
          <button className='btn accept-order-btn'>
            Accept Order
          </button>
        </div>
        :
        <div className='order-accept-container'>
          <button className='btn accept-order-btn' disabled>
            Accept Order
          </button>
        </div> }
        {/* <div className='order-download-container'>
          <button
            // style={{ minWidth: 157 }}
            onClick={() => this.getExcelSummaryForAllMeetingsOnSingleDay(
              [...new Set(restaurant_ids_for_single_day_orders as unknown as number[])],
              dateForDownloadingSummary
            )}
            disabled={isDownloadingExcelSummary}
            className={`btn  order-download-btn ${(this.state.finalArray?.length) > 0 ? 'order-download-btn-selected' : ''}`}
          >
            {isDownloadingExcelSummary ?
              'Downloading...' : 'Download \t'+ `${(this.state.finalArray?.length) > 0 ? `(${this.state.finalArray.length })`:''}`
            }
          </button>
          <button className='btn oder-download-arrow-btn' onClick={this.toggler}>
            <i style={{ fontSize: 14 }} className='far fa-arrow-to-bottom mx-2'></i>
          </button>
          <div className={`${this.state.toggle ? 'download-options-show' : 'download-options-hide'}`}>
            <div className='download-options-list'>
              <p className='p-2'>Summary(PDF)</p>
              <p className='p-2'>Orders(EXCEL)</p>
              <p className='p-2'>Print Labels</p>
            </div>
          </div>
        </div> */}
        <div className='order-download-container'>
        <button
          // style={{ minWidth: 157 }}
          // onClick={() => this.getExcelSummaryForAllMeetingsOnSingleDay(
          //   [...new Set(restaurant_ids_for_single_day_orders as unknown as number[])],
          //   dateForDownloadingSummary
          // )}
          disabled={isDownloadingExcelSummary}
          className={`btn  order-download-btn `}
        >
          {isDownloadingExcelSummary ?
            'Downloading...' : 'Download '
          }
          {/* <i style={{ fontSize: 14 }} className='fas fa-arrow-down mr-2'></i> */}
        </button>
        <button className='btn oder-download-arrow-btn' onClick={toggler}>
          {/* <i class="far fa-arrow-to-bottom"></i> */}
          <i style={{ fontSize: 14 }} className='far fa-arrow-to-bottom mx-2'></i>
        </button>


        <div>
          {toggle && (
            <CustomPopUp
              backdropStyles={{ opacity: 0, cursor: 'default' }}
              onPopUpClose={() => setToggle(false)}
              popUpClassName='order-history-range-picker'
              popUpBodyStyles={{ left: 0 }}
            >
              <div
                style={{ cursor: 'default', width: 172, maxHeight: 160, overflowY: 'auto' }}
                className='bg-white order-restaurant-selector'
              >
               {/* <div className='d-flex align-items-center'>
                            <input
                              type='checkbox'
                              name='select-all'
                              id='select-all-toggler'
                              checked={isAllRestaurantsSelected}
                              onChange={this.handleChange}
                              className='cursor-pointer'
                            />
                            <label htmlFor='select-all-toggler' className='m-0 cursor-pointer pl-3 order-reataurant-names'>
                              {isAllRestaurantsSelected ? 'UnSelect All' : 'Select All'}
                            </label>
                          </div>
                          <hr />
                          {locationDropdown} */}
            <div className='download-options-list'>
              <p className='p-2'>Summary(PDF)</p>
              <p className='p-2'>Orders(EXCEL)</p>
              <p className='p-2'>Print Labels</p>
            </div>
                {/* <div className='download-options-list' >
                  <p className='p-2' onClick={() => getBulkMeetingPDFDownloader(singleDay)}>Summary(PDF)</p>
                  <p className='p-2'onClick={() => getExcelSummaryForAllMeetingsOnSingleDay(
            [...new Set(restaurant_ids_for_single_day_orders as unknown as number[])],
            dateForDownloadingSummary
          )}>Orders(EXCEL)</p>
                  <p className='p-2' onClick={() => getBulkMeetingLabelPDFDownloader(singleDay)}>Print Labels</p>
                </div> */}
              </div>
            </CustomPopUp>)}
        </div>


        {/* <div className={`${toggler ? 'download-options-show' : 'download-options-hide'}`}>
          <div className='download-options-list'>
            <p className='p-2'>Summary(PDF)</p>
            <p className='p-2'>Orders(EXCEL)</p>
            <p className='p-2'>Print Labels</p>
          </div>
        </div> */}
      </div>
      </div>)
    return (
      isEmpty(meetings) ?
        <MeetingsLoading /> :
        <>
          <div className='order-header'>
            <div className='order-header-container'>
              <div className='d-flex justify-content-center align-items-center'>
                <h3 className='text-white order-heading'><span>Upcoming Orders</span></h3>
              </div>
              <div className='order-filters'>
                {restaurantsList?.length > 1 &&
                  <div className='btn-orders order-container-button ml-md-auto position-relative'
                    onClick={() => setIsOpenRestaurents(true)}
                  >
                    <i className='fas fa-solid fa-store' />
                    <span
                      className='mx-2 d-inline-block'
                      style={{ fontSize: 15, fontWeight: 600 }}>
                      {isAllRestaurantsSelected ? 'All' : getPrecedingZero(selectedRestaurantsCount)} Restaurants
                    </span>
                    <i
                      className={`fas fa-solid fa-chevron-up animated-chevron-${isOpenRestaurents ? 'down' : 'up'}`}
                      style={{ fontSize: 15 }}
                    />
                    {isOpenRestaurents &&
                      <CustomPopUp
                        backdropStyles={{ opacity: 0, cursor: 'default' }}
                        onPopUpClose={() => setIsOpenRestaurents(false)}
                        popUpClassName='order-history-range-picker'
                        popUpBodyStyles={{ ...popUpBodyStyles, left: 0 }}
                      >
                        <div
                          style={{ cursor: 'default', width: 425, maxHeight: 281, overflowY: 'auto' }}
                          className='text-dark text-left p-3 oveflow-auto bg-white invisible-scroll order-restaurant-selector'
                        >
                          <div className='d-flex align-items-center'>
                            <input
                              type='checkbox'
                              name='select-all'
                              id='select-all-toggler'
                              checked={isAllRestaurantsSelected}
                              onChange={handleChange}
                              className='cursor-pointer'
                            />
                            <label htmlFor='select-all-toggler' className='m-0 cursor-pointer pl-3 order-reataurant-names'>
                              {isAllRestaurantsSelected ? 'UnSelect All' : 'Select All'}
                            </label>
                          </div>
                          <hr />
                          {locationDropdown}
                        </div>
                      </CustomPopUp>}
                  </div>
                }
                <div className='p-2 rounded cursor-pointer position-relative order-container-calendar'
                  onClick={() => setIsOpenOrderCalendar(true)}
                // style={{ boxShadow: '0px 2px 3px #0000000D' }}
                >
                  <div className='d-flex align-items-center'>
                    <i className={`fas fa-solid fa-calendar`} />
                    <span className='ml-3 mr-4' style={{ fontSize: 15, fontWeight: 600 }}>{dayjs(startDate).format('MM.DD.YYYY')} - {dayjs(endDate).format('MM.DD.YYYY')}</span>
                    <i
                      className={`fas fa-solid fa-chevron-up animated-chevron-${isOpenOrderCalendar ? 'down' : 'up'}`}
                      style={{ fontSize: 15 }}
                    />
                  </div>
                  {isOpenOrderCalendar &&
                    <CustomPopUp
                      backdropStyles={{ opacity: 0, cursor: 'default' }}
                      onPopUpClose={() => setIsOpenOrderCalendar(false)}
                      popUpClassName='order-history-range-picker'
                      popUpBodyStyles={{ ...popUpBodyStyles, right: 0 }}
                    >
                      <DateRangePicker
                        onChange={range => handleSelect(range as { selection: DateRange })}
                        // showSelectionPreview={true}
                        showDateDisplay={false}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={dateRange}
                        staticRanges={defaultStaticRanges}
                        className='bg-white w-100 overflow-auto'
                      />
                    </CustomPopUp>}
                </div>
              </div>
            </div>
            <div className='order-cards-div'>
              {/* <h3 className='mb-4' style={{ paddingTop: '1.25rem' }}>Upcoming Totals</h3> */}
              <div className='text-center upcoming-order-container  order-card-container'>
                {meetings?.total_orders?.map(({ pickup_at, total_orders, total_items }, index: number) =>
                  <div key={pickup_at} className='upcoming-order-card bg-white cursor-default'>
                    <div className='d-flex justify-content-center align-items-center ml-2 mr-4 '>
                      <div className=' mx-3 my-0'>
                        <p className='text-muted pl-1 upcoming-order-cards-title'>Orders</p>
                        <p style={{ fontSize: 28 }}>{getPrecedingZero(total_orders)}</p>
                      </ div>
                      <span className='pt-3 pr-1'>|</span>
                      <div className='pl-2 m-0'>
                        <p className='text-muted pl-1 upcoming-order-cards-title'>Items</p>
                        <p style={{ fontSize: 28 }}>{getPrecedingZero(total_items)}</p>
                      </div>
                    </div>
                    <p className={` text-muted ${index === 0 ? 'today-orders-total' : ''}`} style={{ fontWeight: 600 }}>
                      {index === 0 ? 'Today' : dayjs(pickup_at).format('dddd MM-DD-YY')}
                    </p>
                  </div>
                )}
              </div>

            </div>
          </div>

          {/* <h3 className='mb-4' style={{ paddingTop: '1.25rem' }}>Upcoming Totals</h3>
          <div className='row justify-content-between text-center upcoming-order-container mx-0 order-cards' style={{ background: 'rgb(244, 248, 251)' }}>
            {meetings!.total_orders.map(({ pickup_at, total_orders, total_items }, index: number) =>
              <div key={pickup_at} className='upcoming-order-card bg-white cursor-default'>
                <div className='d-flex justify-content-center align-items-center'>
                  <p className='pr-2'>
                    <span style={{ fontSize: 20 }}>{getPrecedingZero(total_orders)}</span>
                    <span className='text-muted pl-1'>Orders</span>
                  </ p>
                  <span className='mb-2'>|</span>
                  <p className='ps-2'>
                    <span style={{ fontSize: 20 }}>{getPrecedingZero(total_items)}</span>
                    <span className='text-muted ps-1'>Items</span>
                  </p>
                </div>
                <p className={`${index === 0 ? 'today-orders-total' : ''}`} style={{ fontWeight: 600 }}>
                  {index === 0 ? 'Today' : dayjs(pickup_at).format('ddd MMM DD')}
                </p>
              </div>
            )}
          </div> */}
          <div className=' order-order-history ' style={{ gap: 15 }}>
            <div>
              {/* <h3 className='mb-1 pb-1 order-history-heading'>{i18n && i18n.t('Order History')}</h3> */}
            </div>

            {/* <div className='order-accept-parent'>
              {daysInRange && daysInRange[0] ?
                <SingleDayOrdersHeading
                  singleDay={daysInRange[0]}
                  isDownloadingExcelSummary={excel_summary_loading[get_multiple_meetings_excel_summary_loading_prefix + dayjs(daysInRange[0]).format('YYYY-MM-DD')]}
                  dateForDownloadingSummary={dayjs(daysInRange[0]).format('YYYY-MM-DD')}
                  restaurant_ids_for_single_day_orders={Object.keys(allDeliveriesInRange[daysInRange[0]]).map((name: string) => allDeliveriesInRange[daysInRange[0]][name][0].restaurant_address_id)}
                /> :
                <h3 className='my-3'>{i18n && i18n.t('Order History')}</h3>
              }
              <div className='order-accept-container'>
                <button className='btn accept-order-btn '>
                  Accept Order
                </button>
              </div>
            </div> */}
            {/* <div className='order-accept-btn'>
              <button className='btn accept-order-btn '>
                Accept Order
              </button>
            </div> */}
            {/* <div className='d-flex flex-wrap order-1 order-lg-2' style={{ gap: 20, fontWeight: 600 }}>
              {restaurantsList?.length > 1 &&
                <div className='text-white btn btn-orders ms-md-auto position-relative'
                  onClick={() => this.setState({ isOpenRestaurents: true })}
                  style={{ backgroundColor: '#157ffc', minWidth: 168 }}
                >
                  <i className='fas fa-solid fa-store' />
                  <span
                    className='mx-2 d-inline-block text-white'
                    style={{ fontSize: 16, fontWeight: 600 }}>
                    {isAllRestaurantsSelected ? 'All' : getPrecedingZero(selectedRestaurantsCount)} Restaurants
                  </span>
                  <i
                    className={`fas fa-solid fa-chevron-up animated-chevron-${isOpenRestaurents ? 'down' : 'up'}`}
                    style={{ fontSize: 15 }}
                  />
                  {isOpenRestaurents &&
                    <CustomPopUp
                      backdropStyles={{ opacity: 0, cursor: 'default' }}
                      onPopUpClose={() => this.setState({ isOpenRestaurents: false })}
                      popUpClassName='order-history-range-picker'
                      popUpBodyStyles={{ ...popUpBodyStyles, left: 0 }}
                    >
                      <div
                        style={{ cursor: 'default', width: 450, maxHeight: 425, overflowY: 'auto' }}
                        className='text-dark text-left p-3 oveflow-auto bg-white invisible-scroll'
                      >
                        <div className='d-flex align-items-center'>
                          <input
                            type='checkbox'
                            name='select-all'
                            id='select-all-toggler'
                            checked={isAllRestaurantsSelected}
                            onChange={this.handleChange}
                            className='cursor-pointer'
                          />
                          <label htmlFor='select-all-toggler' className='m-0 cursor-pointer ps-3'>
                            {isAllRestaurantsSelected ? 'UnSelect All' : 'Select All'}
                          </label>
                        </div>
                        <hr />
                        {locationDropdown}
                      </div>
                    </CustomPopUp>}
                </div>
              }
              <div className='p-2 rounded cursor-pointer position-relative'
                onClick={() => this.setState({ isOpenOrderCalendar: true })}
                style={{ backgroundColor: '#147BFC', boxShadow: '0px 2px 3px #0000000D' }}>
                <div className='d-flex align-items-center text-white'>
                  <i className={`fas fa-solid fa-calendar`} />
                  <span className='ms-3 me-4'>{dayjs(startDate).format('MM-DD-YYYY')} - {dayjs(endDate).format('MM-DD-YYYY')}</span>
                  <i
                    className={`fas fa-solid fa-chevron-up animated-chevron-${isOpenOrderCalendar ? 'down' : 'up'}`}
                    style={{ fontSize: 15 }}
                  />
                </div>
                {isOpenOrderCalendar &&
                  <CustomPopUp
                    backdropStyles={{ opacity: 0, cursor: 'default' }}
                    onPopUpClose={() => this.setState({ isOpenOrderCalendar: false })}
                    popUpClassName='order-history-range-picker'
                    popUpBodyStyles={{ ...popUpBodyStyles, right: 0 }}
                  >
                    <DateRangePicker
                      onChange={range => this.handleSelect(range as { selection: Range })}
                      // showSelectionPreview={true}
                      showDateDisplay={false}
                      moveRangeOnFirstSelection={false}
                      months={1}
                      ranges={this.props.dateRange}
                      staticRanges={defaultStaticRanges}
                      className='bg-white w-100 overflow-auto'
                    />
                  </CustomPopUp>}
              </div>
            </div> */}
          </div>
          {meetings_loading ?
            <MeetingsLoading isFilterLoading={meetings_loading} /> :
            isEmpty(daysInRange) ?
              <div>
                <h2 className='mt-5 text-center'>No Order Found.</h2>
              </div> :
              daysInRange?.map((singleDay, index: number) => {
                const singleDayOrdersRestaurants = allDeliveriesInRange[singleDay];
                const dateForDownloadingSummary = dayjs(singleDay).format('YYYY-MM-DD');
                const restaurant_ids_for_single_day_orders: number[] = [];
                const isDownloadingExcelSummary = excel_summary_loading[get_multiple_meetings_excel_summary_loading_prefix + dateForDownloadingSummary];
                return <React.Fragment key={singleDay}> {true ? <div key={singleDay}>
                  <DayMeeting
                  singleDay={singleDay}
                  singleDayOrdersRestaurants={singleDayOrdersRestaurants}
                  dateForDownloadingSummary={dateForDownloadingSummary}
                  restaurant_ids_for_single_day_orders={restaurant_ids_for_single_day_orders}
                  isDownloadingExcelSummary={isDownloadingExcelSummary}
                  // restaurantOrdersOnSingleDay={restaurantOrdersOnSingleDay}
                  getExcelSummaryForAllMeetingsOnSingleDay={getExcelSummaryForAllMeetingsOnSingleDay}
                  index={index}
                  getOrderLast24HoursTrend={getOrderLast24HoursTrend}
                  navigateToOrderDetails={navigateToOrderDetails}
                  vendorTimeZone = {vendorTimeZone}
                />
                </div>
                 :
                <React.Fragment key={singleDay}>
                  <SingleDayOrdersHeading
                    singleDay={singleDay}
                    isDownloadingExcelSummary={isDownloadingExcelSummary}
                    dateForDownloadingSummary={dateForDownloadingSummary}
                    restaurant_ids_for_single_day_orders={restaurant_ids_for_single_day_orders}
                  />
                  {/* {index === 0 ? null :
                  } */}
                  <div className='table-responsive mb-3 bg-primary'>
                    {Object.keys(singleDayOrdersRestaurants)?.map((restaurantName: string) => {
                      const restaurantOrdersOnSingleDay = singleDayOrdersRestaurants[restaurantName];
                      return <>
                      { false ?
                      <div style={{ marginBottom: 10 }} key={restaurantName}>
                        <div className='d-flex align-items-center pb-2 pt-4'>
                          <div className='d-flex align-items-center'>
                            <input
                              type='checkbox'
                              name='select-all'
                              id='select-all-toggler'
                              checked={isAllRestaurantsSelectedNew}
                              onChange={handleChangeRestaurants}
                              className='cursor-pointer'
                            />
                            <label htmlFor='select-all-toggler' className='m-0 cursor-pointer pl-3 order-reataurant-names'>
                              {isAllRestaurantsSelectedNew ? 'UnSelect All' : 'Select All'}
                            </label>
                          </div>
                          <div>
                          </div>
                          <label htmlFor="select-all-orders" className='mb-0'>
                            <i className='fas fa-store' style={{ color: '#157FFC' }} />
                            <h5 className='pl-2 m-0 d-inline-block' style={{ color: '#157FFC' }}>{restaurantName}</h5>
                          </label>
                        </div>
                        <table className='table order-history-records table-hover border-0 table-data text-center'>
                          <tbody>
                            {restaurantOrdersOnSingleDay?.map(({
                              pickup,
                              status,
                              order_id,
                              cutoff_at,
                              items_count,
                              restaurant_address_id,
                              new_items_in_last24_hours,
                              checked
                            },idx,array) => {
                              restaurant_ids_for_single_day_orders.push(restaurant_address_id);
                              const last24HoursOrderTrend = getOrderLast24HoursTrend(new_items_in_last24_hours || 0);
                              const isCutoffReached = dayjs(cutoff_at)?.isBefore(new Date());
                              return <tr className='bg-white tableList border-bottom cursor-default' key={order_id} >
                                <td className='order-checkbox'>
                                  <input type="checkbox" name="single-order" id="single-order" checked={checked} onChange={(e)=> checkerselect(e,singleDay,order_id,idx,restaurantName)} />
                                </td>
                                <td className='border-0 text-nowrap text-left table-data-padding'>
                                  <span style={{ fontSize: 18 }}>{order_id}</span>
                                  <p style={{ fontSize: 16 }}>Order ID</p>
                                </td>
                                <td className='border-0 text-nowrap text-left'>
                                  <span style={{ fontSize: 18 }}>{dayjs(pickup).format('hh:mm a')}</span>
                                  <p style={{ fontSize: 16 }}>Pickup</p>
                                </td>
                                <td className='border-0 text-nowrap text-left'>
                                  <span style={{ fontSize: 18 }}>{getPrecedingZero(items_count)}</span>
                                  <p style={{ fontSize: 16 }}>Items
                                    {last24HoursOrderTrend ?
                                      <span style={{ color: last24HoursOrderTrend.color, paddingLeft: 1 }}>
                                        (<i className={`fa-solid fa-arrow-trend-${last24HoursOrderTrend.className} mx-1`}></i>
                                        <span className='me-1' style={{ fontSize: 16 }}>{last24HoursOrderTrend.count}</span>)
                                      </span> : null}
                                  </p>
                                </td>
                                <td className='border-0 text-nowrap text-left'>
                                  <span
                                    data-tip
                                    className='text-capitalize'
                                    data-for={`order-history-${order_id}`}
                                    style={{ fontSize: 18, color: isCutoffReached ? '#dc3545' : '#157ffc' }}
                                  >
                                    {isCutoffReached ? 'Reached' : dayjs(cutoff_at).toNow(true)}
                                  </span>
                                  {window.navigator.userAgent.match(/MSIE|Trident/) === null &&  // check if not IE
                                    <ReactTooltip
                                      type='dark'
                                      place='bottom'
                                      effect='solid'
                                      className='react-tooltip'
                                      id={`order-history-${order_id}`}
                                    >
                                      <span>{getZonedTime(cutoff_at, 'EEE. MMM dd h:mma, zzzz', vendorTimeZone)}</span>
                                    </ReactTooltip>
                                  }
                                  <p style={{ fontSize: 16 }}>Cutoff</p>
                                </td>
                                <td className='border-0 text-nowrap text-left' style={{ width: 165 }}>
                                  <span
                                    className='confirmed text-capitalize '
                                    //@ts-ignore
                                    style={{ backgroundColor: OrderStatus[status] }}
                                  >
                                    {status}
                                  </span>
                                </td>
                                <td className='border-0 text-nowrap text-right pr-4' style={{ width: 150 }}>
                                  <button
                                    className='btn btn-sm btn-light'
                                    onClick={() => navigateToOrderDetails(restaurant_address_id, order_id)}
                                  >
                                    View Details
                                  </button>
                                </td>
                              </tr>
                            }

                            )}
                          </tbody>
                            </table>
                          </div>
                          :
                          <div key={restaurantName} className='bg-primary'>

                            <SingleDayMeetings
                              singleDay={singleDay}
                              index={restaurantName}
                              restaurant_ids_for_single_day_orders={restaurant_ids_for_single_day_orders}
                              getOrderLast24HoursTrend={getOrderLast24HoursTrend}
                              navigateToOrderDetails={navigateToOrderDetails}
                              vendorTimeZone={vendorTimeZone}
                              restaurantOrdersOnSingleDay={restaurantOrdersOnSingleDay}
                              restaurantName={restaurantName}

                            />
                          </div>
                        }</>
                    }
                    )}
                    </div>
                  </React.Fragment>}
                </React.Fragment>
              })
          }
        </>
    )
  }
export default AllLocationsOrders;

const MeetingsLoading = ({ isFilterLoading = false }: { isFilterLoading?: boolean }) => {
  return <>
    {!isFilterLoading ?
      <div className='order-header'>
        <div className='order-header-container'>
          <div className='d-flex justify-content-center align-items-center'>
            <h3 className='text-white order-heading'>
              <Skeleton width={239} height={32} />
            </h3>
          </div>
          <div className='order-filters'>
            <Skeleton width={239} height={32} />
            <div className='rounded cursor-pointer position-relative'
            >
              <Skeleton width={239} height={32} />
            </div>
          </div>
        </div>
        <div className='order-cards-div'>
          <div className='text-center upcoming-order-container  order-card-container'>
            {ArrayOfLength(5)?.map((item) =>
              <div className='upcoming-order-card border-0 p-0' key={item}>
                <Skeleton width={200} height={95} />
              </div>
            )}
          </div>
        </div>
        <div className=''>
        <Skeleton width={200} height={40} />
        </div>
      </div> :
      null
    }
    {ArrayOfLength(2)?.map(index => {
      const singleDayOrders = ArrayOfLength(3 - index);
      return <React.Fragment key={index}>
        <div className='date d-flex flex-wrap align-items-center mt-4 mb-2 pt-4 '>
          <h4 className='m-0'>
            <Skeleton width={84} height={25} />
          </h4>
          <button className='ml-3 btn btn-light overflow-hidden p-0'>
            <Skeleton height={35} width={158} />
          </button>
          <button className=' btn btn-light overflow-hidden p-0'  style={{ marginLeft: 'auto' }}>
            <Skeleton height={35} width={158} />
          </button>
        </div>
        <div className='table-responsive mb-3'>
          <table className='table order-history-records table-hover border-0 table-data text-center'>
            <tbody>
              {singleDayOrders?.map((rowIndex) =>
                <tr className='border-bottom bg-white' key={rowIndex + index}>
                  <td className='border-0 p-2'>
                    <Skeleton width='100%' height={65} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    })}
  </>
}